import * as React from "react";
import {graphql, PageProps} from "gatsby";

import CleanDataParser from "../helpers/editorjs-parser";
import Layout from "../components/layout";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import BenefitsSliderServices from "../components/slider/benefits-slider-services";
import Section from "../components/section";
import ServiceTabs from "../components/tab/services-tabs";
import StepSection from "../components/step-section";
import TemplateHead from "../components/template-head";

import "../styles/page-services.scss";


type DataProps = {
  strapiAdvisor: any,
  lpaPageData
}

export default function DirectCremation({data: {strapiAdvisor, lpaPageData}}: PageProps<DataProps>) {
  const advisor = strapiAdvisor

  const pageTextSimple = (
    <div>
      {lpaPageData.textSimple?.data?.textSimple ? (
        JSON.parse(lpaPageData.textSimple?.data?.textSimple).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const textRemember = (
    <div>
      {lpaPageData.textRemember?.data?.textRemember ? (
        JSON.parse(lpaPageData.textRemember?.data?.textRemember).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--funeral pl-4 pr-4"
        bgImageClassName="hero-image hero-image--legal"
        contentClassName="hero-content hero-content--legal"
        altImages="Lasting Powers of Attorney Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[
          {name: "Fenix Home", slug: "/"},
          {name: "Lasting Powers of Attorney", slug: "/lasting-power-attorney/"},
        ]}
      >
        <HeroContentHeading
          headingTitle={lpaPageData.title}
          headingContent={lpaPageData.subtitle}
          priceFrom={"from "}
          price={lpaPageData.priceCurrency + " " + lpaPageData.price}
          advisorData={advisor}
          citeText=""
        />
      </Hero>
      <Section
        sectionClass={
          "bg-deepblue pl-4 pr-4 pt-5 pb-5 section-container section-container--incl"
        }
      >
        <div className="block-wrapper">
          <div className="row no-gutters">
            <div className="col-0 col-md-5"></div>
            <div className="col-12 col-md-7 text-white">
              <h2>{lpaPageData.titleSimple}</h2>
              {pageTextSimple}
            </div>
          </div>
        </div>
        <img
          src="/images/stripe_1-yellow.svg"
          alt="Fenix Funeral Directors"
          className="stripe stripe--1 stripe--services d-none d-lg-block"
        />
      </Section>
      <BenefitsSliderServices
        sliderClass="bg-deepblue pl-0 pr-0 pl-sm-4 pr-sm-4 pt-2 pt-sm-5 pt-md-0 pb-5"
        benefitsData={lpaPageData.slider}
      />
      <Section
        sectionClass={
          "bg-deepblue pl-0 pl-md-4 pr-0 pr-md-4 pt-0 section-container section-container--tabs"
        }
      >
        <ServiceTabs
          serviceClass="legal"
          includedServices={lpaPageData.tab}
          includedServicesTitle={lpaPageData.titleTabs}
        />
        <img
          src="/images/stripe_1-yellow.svg"
          alt="Fenix Funeral Directors"
          className="stripe stripe--1 stripe--services d-none d-lg-block"
        />
      </Section>
      <StepSection
        serviceClass="legal"
        componentClass="pl-4 pr-4 section-container step step--section step--legal"
        stepData={lpaPageData.guidance}
        stepDataTitle={lpaPageData.titleGuide}
        stepDataCta={"Find our more, with no commitments:"}
      />
      <Section
        sectionClass={
          "bg-deepblue pl-4 pr-4 pt-5 pb-5 section-container section-container--incl"
        }
      >
        <div className="block-wrapper">
          <div className="row no-gutters">
            <div className="col-0 col-md-5"></div>
            <div className="col-12 col-md-7 text-white">
              <h2>{lpaPageData.titleRemember}</h2>
              {textRemember}
            </div>
          </div>
        </div>
        <img
          src="/images/stripe_1-yellow.svg"
          alt="Fenix Funeral Directors"
          className="stripe stripe--1 stripe--services d-none d-lg-block"
        />
      </Section>
      <TrustpilotCarousel/>
    </Layout>
  );
}

export function Head() {
  return (
    <TemplateHead
      title="Lasting Powers of Attorney - Fenix Funeral Directors"
      shortlink="https://fenixfuneral.co.uk/lasting-power-attorney/"
      alternate="https://fenixfuneral.co.uk/lasting-power-attorney/"
      canonical="https://fenixfuneral.co.uk/lasting-power-attorney/"
      og={{
        url: "https://fenixfuneral.co.uk/lasting-power-attorney/",
        title: "Lasting Powers of Attorney - Fenix Funeral Directors",
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "position": "1",
          "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"},
          {
          "@type": "ListItem",
          "position": "2",
          "name": "Lasting Powers of Attorney","item": "https://fenixfuneral.co.uk/lasting-power-attorney/"
          }`
      }
    />
  );
}

export const pageQuery = graphql`
    query {
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400, 
                            height: 400, 
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
        lpaPageData: strapiLastingPowersOfAttorney {
            title
            subtitle
            price
            priceCurrency
            titleSimple
            textSimple {
                data {
                    textSimple
                }
            }
            slider {
                title
                icon {
                    url
                }
                textMain
            }
            titleTabs
            tab {
                title
                text {
                    data {
                        text
                    }
                }
            }
            titleGuide
            guidance {
                titleNumber
                textGuide {
                    data {
                        textGuide
                    }
                }
            }
            titleRemember
            textRemember {
                data {
                    textRemember
                }
            }
        }
    }
`;